@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: natParkRegular;
    src: url("../public/fonts/NationalPark-Regular.otf");
}

 html {
    background-color: #f6f6ef;
}

.natParkRegular {
    font-family: 'natParkRegular';
}

.titlebar {
    max-height: 25vh;
}

.randompumpkin {
    max-height: 30vh;
}

.pumpkinBanner {

    max-height:110px;
}

.pumpkinBannerImg {

}